.modal_para {
  color: #727176;
  font-size: 14px;
  font-weight: 400;
}

.modal_note {
  margin: 20px 0px;
  padding: 15px 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(19.5px);
}
