.address_page {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 10px 24px;
  font-family: Poppins;
  padding-top: 10px;
}
.address_container {
  width: 100%;
}
.create_address_container {
  width: 34%;
}
.address_container {
  padding-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;
  row-gap: 3em;
}
.address_card_main {
  flex: 1;
  position: relative;
}
.default_tag {
  background: #fff4ef;
  color: #fd7b35;
  font-weight: 500;
  border: 2px solid rgba(255, 221, 203, 0.7);
  margin: 0;
  width: 40%;
  border-radius: 10px 10px 0 0;
  padding: 5px;
  text-align: center;
  position: absolute;
  top: -36px;
  left: -2px;
}
.address_card {
  font-family: Poppins;
  border: 2px solid #fff;
  background: linear-gradient(
    107deg,
    rgba(255, 255, 255, 0.7) 44.17%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: space-between;
  position: relative;
}
.address_head {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  overflow-wrap: break-word;
}
.address_info {
  display: grid;
  grid-template-columns: 0.5fr 2fr;
  gap: 0.5em;
  row-gap: 1em;
}
.address_info p {
  margin: 0;
}
.color_grey {
  color: #727176;
}

.address_card .button {
  border-top: 1px solid #ddd;
  background: #f4f4f4;
  width: 100%;
  border-radius: 0 0 10px 10px;
  text-align: center;
  color: #727176;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
}
.create_address_container {
  border-radius: 7px;
  border: 2px solid #fff;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  padding: 20px;
}
.create_address_container label {
  font-family: Poppins;
  font-size: 14px;
}
.create_address_container input {
  width: 100%;
}
.error {
  color: red;
}

/* add address page */
.form_container {
  border-radius: 10px;
  border: 2px solid #fff;
  background: linear-gradient(
    107deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.05);
  margin: 20px 30px;
  padding: 20px;
}
.form_container label {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.form_container .button_group {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.form_container .button_group > span {
  border-radius: 10px;
  border: 2px solid rgba(238, 238, 238, 0.87);
  background: rgba(244, 244, 244, 0.4);
  color: #727176;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 22px;
}

.form_container .button_group .active {
  background: #eeeeee;
  color: #000;
}

.pincode_subline {
  color: #727176;
  font-size: 12px;
  margin-top: 5px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 15px;
}

.footer_container {
  margin: 0 30px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.footer_container > button {
  border-radius: 5px !important;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
