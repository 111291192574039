.conatiner {
  margin: 0;
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
  border: 3px solid #fff;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-family: Poppins;
}
.conatiner > div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  height: 55px;
  width: 33%;
}
.conatiner > div:first-child {
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
}
.conatiner > div:last-child {
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}
.start_end_label {
  color: #727176;
  font-size: 13px;
  font-weight: 600;
}
.time_label {
  color: #312b81;
  font-size: 15px;
  font-weight: 600;
}

/*Create Modal Css */
.modal_head {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 12px;
  font-family: Poppins;
}
.modal_footer {
  background: #fff;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: end;
  gap: 20px;
  font-family: Poppins;
}
.modal_body {
  padding: 10px 20px;
  font-family: Poppins;
}
.modal_body > label {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.activity_group {
  /* border: 1px solid #ddd; */
  border-radius: 10px;
}
.activity_group > div {
  border: 1px solid #ddd;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    linear-gradient(
      94deg,
      rgba(255, 255, 255, 0.7) 8.19%,
      rgba(255, 255, 255, 0.4) 91.78%
    );
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 0;
  color: #727176;
  font-size: 14px;
  font-weight: 600;
}
.activity_group > div > img {
  padding-left: 20px;
}
.modal_label_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.modal_label_container > span:first-child {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #727176;
  font-size: 16px;
  font-weight: 500;
}
.active_activity {
  border: 1px solid #312b81 !important;
}
.disabled {
  opacity: 0.5;
}

/* Attendance Deatil Modal */
.detail_container {
  padding: 10px 20px;
  font-family: Poppins;
}
.activity_head {
  display: flex;
  gap: 10px;
  align-items: center;
}
.head_name {
  font-size: 16px;
  font-weight: 500;
}
.head_date {
  color: #656565;
  font-size: 10px;
}
.activity_body > label {
  color: #727176;
  font-weight: 500;
  display: block;
  margin-bottom: 5px;
}
.activity_joint_activity {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.activity_location {
  color: #000;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.activity_location > div {
  word-break: break-all;
}
.activity_comment {
  font-weight: 500;
  text-align: justify;
}
.activity_selfie {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.activity_selfie img {
  background: #ddd;
  border-radius: 3px;
}
