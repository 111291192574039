.delet_button {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-top: 40px;
  padding: 20px;
}
.delet_h1 {
  color: #727176;
  font-size: 20px;
  margin: 0px;
  font-weight: 600;
  padding: 20px;
}
.delet_p {
  margin: 0px;
  font-weight: 600;
  color: #727176;
  font-size: 13px;
  padding: 0px 20px;
}
.delet_main{
  padding: 20px;
}
