.modal_title {
  padding: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid #eee;
}
.form_options {
  background: linear-gradient(
    107.41deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  border: 1px solid #ffffff;
  box-shadow: 2px 6px 12px 4px #00000005;
  display: flex;
  text-align: center;
  cursor: pointer;
}
.active_form_option {
  color: #322e80;
  border-bottom: 2px solid #322e80;
}
.modal_footer_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
}
